<template>
   <div>
      <!-- Breadcrumb start -->
       <section class="bradcrumb_sec">
         <div class="container-fluid custome_container">
            <div class="row justify-content-center">
               <div class="col-md-11">
                  <div class="bread_crumb_box mb_22">
                     <h2 class="heading_1 font_bold mb_23">Terms Of Service</h2>
                     <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                           <li class="breadcrumb-item"><a href="/">Home</a></li>
                           <li class="breadcrumb-item active" aria-current="page">Terms Of Service</li>
                        </ol>
                     </nav>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- Breadcrumb end -->
      <!-- Terms start -->
      <section class="terms_sec">
         <div class="container">
            <div class="row">
               <div class="col-md-12">
                  <div class="terms_box">
                     <p>
                        <span class="font_bold">Welcome to Pbergo.com!</span>
                        By using this site and services, users agree to be bound by these terms.
                        The information provided in herein is intended for use as a resource and for educational purposes
                        only and does not constitute legal, medical or other professional advice on any subject matter.
                        None of our information is a substitute for advice from your physician or other health care 
                        provider.
                     </p>
                     <p>
                        <span class="font_bold">No Warranty/Limitation of Liability. </span>
                        Pbergo.com makes no warranties of any kind, express or implied. Pbergo.com is provided “as is” without any express or implied warranty of any kind. Pbergo.com does not warrant or assume responsibility for the accuracy or completeness of any information, text, graphics, links, or other items contained within. Pbergo.com shall have no liability with respect to the content therein or any part thereof, including but not limited to errors or omissions contained therein, libel, infringements of rights of publicity, privacy, trademark rights, business interruption, personal injury, loss of privacy, moral rights, or the disclosure of confidential information.
                     </p>
                     <p>
                        <span class="font_bold">International Use.</span>
                        Pbergo.com makes no representation that the Content on the Website is appropriate for use in locations outside the United States. Those who choose to access this Website outside the United States do so on their own initiative and are responsible for compliance with local laws.
                     </p>
                     <p>
                        <span class="font_bold">Changes to Terms.</span>
                        Pbergo.com reserves the right to modify these Terms at any time, and each such modification will be effective upon posting on the Site. All material modifications will apply prospectively only. To stay informed of any changes, please review the most current version of these Terms posted on the Site. If you do not agree to be bound by these Terms, you must stop using the Site immediately. Pbergo.com assumes no liability or responsibility for your failure to review the current version of these Terms.
                     </p>
                     <p>
                        <span class="font_bold">User Indemnity.</span>
                        You agree to defend, indemnify, and hold Pbergo.com, its affiliate partners, and their respective affiliates harmless from and against any claims, liabilities, damages, losses, and expenses, including reasonable attorney fees and costs, in connection with:
                     <ul>
                        <li>
                           <p>your violation of these Terms;</p>
                        </li>
                        <li>
                           <p>your violation of any third-party right, including any intellectual property right; or</p>
                        </li>
                        <li>
                           <p>any third-party claim of damage</p>
                        </li>
                     </ul>
                     This indemnity obligation will survive your use of the information/resources provided on this site.
                     </p>
                     <p>
                        <span class="font_bold">Severability.</span>
                        If any provision of this Agreement or the application thereof to any person or circumstance shall, to any extent, be invalid or unenforceable, the remainder of this Agreement shall not be affected thereby, and each provision of this Agreement shall remain in effect and be enforceable to the fullest extent permitted by law.
                     </p>
                     <p class="text-center privacy_text">
                        <router-link :to="{ name: 'privacy_policy' }">
                           <span class="font_bold">Privacy Policy</span> 
                        </router-link>
                        can be viewed by clicking here.
                     </p>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- Terms end -->
   </div>
</template>
<script>
   export default {
      name: "terms_of_service"
   }
</script>